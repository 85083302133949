
import {defineComponent, ref} from 'vue';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {IToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {swalErrNotification, swalNotification} from '@/core/helpers/utils';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import DivCol from '@/layout/_shared/DivCol.vue';
import DivRow from '@/layout/_shared/DivRow.vue';
import LabelCustom from '@/components/shared/label-custom/LabelCustom.vue';
import PageRowLayout from "@/layout/_shared/PageRowLayout.vue";
import ToaXeService from '@/core/services/ToaXe.service';

export default defineComponent({
	name: 'toa-xe-chi-tiet',

	components: {
    ButtonCustom,
    ConfirmationDialog,
    DivCol,
    DivRow,
    LabelCustom,
    PageRowLayout,
  },

	data() {
		return {
			removeItemDialogDisplay: false,
		}
	},

	setup() {
		const {goBack, push, id} = useRouterCustom();
		const {moment} = useDateTime();
		const { ButtonTypeColors, ButtonsType } = useButtonCustom();

		const toaXe = ref<IToaXeResponseBase | null>(null);

		return {
			toaXeId: id,
			toaXe,
			moment,
			goBack, push,
			ButtonTypeColors, ButtonsType,
		}
	},

	async mounted() {
		setCurrentPageTitle("Toa xe");
		try {
			const { data: { data: res } } = await ToaXeService.get(this.toaXeId);
			this.toaXe = res;
		} catch (e) {
			await swalErrNotification(e, 'Có lỗi xảy ra, không thể xóa toa xe này');
		}
	},

	methods: {
		async confirmDelete() {
			this.removeItemDialogDisplay = false;
			try {
				await ToaXeService.delete(this.toaXeId);
				await swalNotification(
					'Xóa thành công',
					'success'
				);
				await this.push('/quan-ly/toa-xe/danh-sach');
			} catch (error) {
				await swalErrNotification(error, 'Có lỗi xảy ra, không thể xóa toa xe này');
			}
		}
	}
})
