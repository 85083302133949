import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_LabelCustom = _resolveComponent("LabelCustom")!
  const _component_DivCol = _resolveComponent("DivCol")!
  const _component_DivRow = _resolveComponent("DivRow")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmationDialog, {
      title: "Bạn có chắc chắn muốn xóa dữ liệu này?",
      width: "20%",
      "is-display": _ctx.removeItemDialogDisplay,
      onOnConfirmButtonClick: _ctx.confirmDelete,
      onOnCloseButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeItemDialogDisplay = false)),
      onOnCloseIconClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeItemDialogDisplay = false))
    }, {
      default: _withCtx(() => [
        (_ctx.toaXe)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.toaXe.ten_toa_xe), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-display", "onOnConfirmButtonClick"]),
    _createVNode(_component_PageRowLayout, null, {
      "page-row-header": _withCtx(() => [
        _createTextVNode("Thông tin")
      ]),
      "page-row-action-button": _withCtx(() => [
        _createVNode(_component_ButtonCustom, {
          "button-type": _ctx.ButtonsType.EDIT,
          "color-type": _ctx.ButtonTypeColors.SUCCESS,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.push(`/quan-ly/toa-xe/${_ctx.toaXeId}/chinh-sua`)))
        }, null, 8, ["button-type", "color-type"]),
        _createVNode(_component_ButtonCustom, {
          "button-type": _ctx.ButtonsType.TRASH,
          "color-type": _ctx.ButtonTypeColors.DANGER,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeItemDialogDisplay = true))
        }, null, 8, ["button-type", "color-type"]),
        _createVNode(_component_ButtonCustom, {
          "button-type": _ctx.ButtonsType.BACK,
          onClick: _ctx.goBack
        }, null, 8, ["button-type", "onClick"])
      ]),
      "page-row-content": _withCtx(() => [
        (_ctx.toaXe)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_DivRow, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DivCol, { cols: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                        default: _withCtx(() => [
                          _createTextVNode("Tên:")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DivCol, { cols: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-bolder": true }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.toaXe.ten_toa_xe), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DivCol, { cols: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                        default: _withCtx(() => [
                          _createTextVNode("ID:")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DivCol, { cols: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-bolder": true }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.toaXe.id), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivRow, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DivCol, { cols: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                        default: _withCtx(() => [
                          _createTextVNode("Mã:")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DivCol, { cols: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-bolder": true }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.toaXe.ma_toa_xe), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DivCol, { cols: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                        default: _withCtx(() => [
                          _createTextVNode("Số hiệu:")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DivCol, { cols: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-bolder": true }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.toaXe.so_hieu), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_DivRow, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DivCol, { cols: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                        default: _withCtx(() => [
                          _createTextVNode("Ghi chú:")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_DivCol, { cols: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.toaXe.ghi_chu,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.toaXe.ghi_chu) = $event)),
                        type: "textarea"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  (_ctx.toaXe.danh_muc_toa_xe?.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_DivCol, { cols: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_LabelCustom, { "is-text-muted": true }, {
                              default: _withCtx(() => [
                                _createTextVNode("D/m toa xe:")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_DivCol, { cols: 4 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_RouterLink, {
                              to: {
                  name: 'chi-tiet-danh-muc-toa-xe',
                  params: {
                    id: _ctx.scope.row.danh_muc_toa_xe[0].id
                  }
                }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.toaXe.danh_muc_toa_xe[0].ten_danh_muc), 1)
                              ]),
                              _: 1
                            }, 8, ["to"])
                          ]),
                          _: 1
                        })
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}