
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'label-custom',

  props: {
    isTextMuted: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isTextDark: {
      type: Boolean,
      default: false,
    },
    isBolder: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    classComputed() {
      return `d-flex align-items-center ${this.isBolder ? 'fw-bolder' : 'fw-bold'}  ${this.isTextMuted ? 'text-muted' : ''} ${this.isRequired ? 'required' : ''} ${this.isTextDark ? 'text-dark' : ''}`.trim().replaceAll('  ', ' ');
    }
  }
});
