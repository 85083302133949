import {
  ApiResponse, IToaXeResponseBase, PaginationResponseBase
} from '@/core/interfaces/ApiResponses';
import { apiServiceInstance } from "@/core/helpers/utils";

export default class ToaXeService {
  static list(
    current_page = 1,
    per_page = 200,
    id_danh_muc_toa_xe = 0,
    search = '',
  ): Promise<{
    data: ApiResponse<PaginationResponseBase<IToaXeResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/toa-xe/danh-sach',
      {
        params: {
          page: current_page,
          ...(id_danh_muc_toa_xe && {
            id_danh_muc_toa_xe,
          }),
          ...(search && {
            s: search,
          }),
        },
        headers: {
          perpage: per_page,
        }
      }
    );
  }

  static get(id: number): Promise<{
    data: ApiResponse<IToaXeResponseBase>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/toa-xe/chi-tiet/${id}`,
    );
  }

  static create(
    ten_toa_xe: string,
    ma_toa_xe: string,
    so_hieu: string,
    ghi_chu: string,
    id_chung_loai: number,
  ): Promise<{
    data: ApiResponse<IToaXeResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('toaXeInstance[ten_toa_xe]', ten_toa_xe);
    bodyFormData.append('toaXeInstance[ma_toa_xe]', ma_toa_xe);
    bodyFormData.append('toaXeInstance[so_hieu]', so_hieu);
    bodyFormData.append('toaXeInstance[ghi_chu]', ghi_chu);
    bodyFormData.append('toaXeInstance[id_chung_loai]', id_chung_loai.toString());

    return apiServiceInstance().vueInstance.axios.post(
      '/api/v1/toa-xe/tao-moi',
      bodyFormData
    );
  }

  static update(
    id: number,
    ten_toa_xe: string,
    ma_toa_xe: string,
    so_hieu: string,
    ghi_chu: string,
    id_chung_loai: number,
  ): Promise<{
    data: ApiResponse<IToaXeResponseBase>
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
      ] = "multipart/form-data";
    const bodyFormData = new FormData();

    bodyFormData.append('toaXeInstance[ten_toa_xe]', ten_toa_xe);
    bodyFormData.append('toaXeInstance[ma_toa_xe]', ma_toa_xe);
    bodyFormData.append('toaXeInstance[so_hieu]', so_hieu);
    bodyFormData.append('toaXeInstance[ghi_chu]', ghi_chu);
    bodyFormData.append('toaXeInstance[id_chung_loai]', id_chung_loai.toString());

    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/toa-xe/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static delete(id: number) {
    return apiServiceInstance().vueInstance.axios.post(`/api/v1/toa-xe/xoa/${id}`);
  }
}
